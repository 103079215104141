import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { Link, graphql } from "gatsby";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import ArticleBanner from "../components/ArticleBanner/ArticleBanner";
import SubscribeForm from "../components/SubscribeForm/SubscribeForm";
import FeaturedIn from "../components/FeaturedIn/FeaturedIn";
import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";

import "./NonCityArticlePage.css";

import { getActivitiesByCity as apiGetActivitiesByCity } from "../services/api";

import { GlobalStateContext } from "../context/GlobalContextProvider";

import YoutubeIcon from "../images/youtube-3-xl.png";
import LinkedInIcon from "../images/linkedin-3-xl.png";
import TikTokIcon from "../images/tiktok-2-xxl.png";

// import authors from "../../authors.json";

const utils = require("../services/utils");

const createSlug = (title) => {
  const slug = title.replace(/\W+/g, "-").toLowerCase();
  return slug;
};

export const query = graphql`
  query ($articleId: String!) {
    contentfulArticle(id: { eq: $articleId }) {
      title
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      youtubeLink
      showBanner
      bannerText {
        childMarkdownRemark {
          html
        }
      }
      bannerCtaText
      bannerUrl
      thumbnailImageUrl
      content {
        childMarkdownRemark {
          html
        }
      }
      followLinks {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      updatedAt
    }
  }
`;

const formatHtml = (html, followLinks) => {
  function replaceLinks(html) {
    // Regular expression to match <a> tags
    var regex = /<a\s+(.*?)>/gi;

    // Function to handle each match found
    function replaceCallback(match, attributes) {
      // Check if href attribute exists
      console.log("FOLLOW LINKS", followLinks);
      if (attributes.includes("href=")) {
        // Regular expression to match href attribute
        var hrefRegex = /href=['"](.*?)['"]/i;
        var hrefMatch = attributes.match(hrefRegex);
        if (hrefMatch && hrefMatch[1]) {
          var href = hrefMatch[1];
          if (href.indexOf("travel-lingual.com") === -1) {
            if (followLinks && followLinks.indexOf(href) !== -1) {
              var relRegex = /rel=['"](.*?)['"]/i;
              var relMatch = attributes.match(relRegex);
              if (relMatch && relMatch[1]) {
                // If rel attribute exists, replace it with "nofollow" and add "target=_blank"
                return match.replace(relRegex, 'target="_blank"');
              } else {
                // If rel attribute doesn't exist, add "rel=nofollow" and "target=_blank"
                return match.replace(/<a\s+(.*?)>/i, '<a $1 target="_blank">');
              }
            } else {
              console.log("match", match);
              var relRegex = /rel=['"](.*?)['"]/i;
              var relMatch = attributes.match(relRegex);
              if (relMatch && relMatch[1]) {
                // If rel attribute exists, replace it with "nofollow" and add "target=_blank"
                return match.replace(
                  relRegex,
                  'rel="nofollow" target="_blank"'
                );
              } else {
                // If rel attribute doesn't exist, add "rel=nofollow" and "target=_blank"
                return match.replace(
                  /<a\s+(.*?)>/i,
                  '<a $1 rel="nofollow" target="_blank">'
                );
              }
            }
          }
        }
      }
      // If condition doesn't match, return the original match
      return match;
    }

    // Perform the replacement
    return html.replace(regex, replaceCallback);
  }

  // Add rel="nofollow" and target="_blank" to <a> tags
  html = html.replace(
    /<a\s+(?![^>]*rel=['"]nofollow['"])(?![^>]*href=['"](https:\/\/travel-lingual\.com\/)([^"']*))([^>]*)>/gi,
    '<a $3 rel="nofollow" target="_blank">'
  );

  // Add loading="lazy" to <img> tags except the first 3
  let imageCount = 0;
  html = html.replace(
    /<img\s+(?![^>]*loading=['"]lazy['"])([^>]*)>/gi,
    (match, group) => {
      if (imageCount < 1) {
        // Skip lazy loading for the first 3 images
        imageCount++;
        return `<img ${group} style="height: auto; width: 100%; aspect-ratio: 16 / 11; object-fit: contain;">`;
      } else {
        return `<img ${group} loading="lazy" style="height: auto; width: 100%; aspect-ratio: 16 / 11; object-fit: contain;">`;
      }
    }
  );

  html = replaceLinks(html, followLinks);

  return html;
};

const NonCityArticleTemplate = ({ pageContext, data }) => {
  const context = React.useContext(GlobalStateContext);

  const { city } = pageContext;
  const {
    title,
    content,
    youtubeLink,
    showBanner,
    bannerText,
    bannerUrl,
    bannerCtaText,
    followLinks,
    updatedAt,
  } = data.contentfulArticle;

  const [cityActivities, setCityActivities] = React.useState([]);
  const [loadingCityActivities, setLoadingCityActivities] =
    React.useState(false);
  const [showVideo, setShowVideo] = useState(false);

  // Format the date as you desire (for example, in a human-readable format)
  var options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(updatedAt).toLocaleDateString(
    "en-US",
    options
  ); // Change the format as needed

  const getActivitiesByCity = async () => {
    // setErrors([]);

    try {
      setLoadingCityActivities(true);

      var categories = "";
      var minPrice = 0;
      var maxPrice = 450;

      const data = await apiGetActivitiesByCity(
        city.id,
        context.currentCurrency,
        categories,
        minPrice,
        maxPrice
      );

      if (data) {
        setCityActivities(data);
      }
      setLoadingCityActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingCityActivities(false);
    }
  };

  React.useEffect(() => {
    if (city) {
      getActivitiesByCity();
    }
  }, [city, context.currentCurrency]);

  let countryPath = "";
  let regionPath = "";
  let cityPath = "";

  if (city) {
    countryPath = `/${createSlug(city.country.name)}`;

    if (city.regionCities && city.regionCities.length > 0) {
      regionPath = `/${createSlug(
        city.country.name
      )}/${city.regionCities[0].region.slug.replace(
        `-${createSlug(city.country.name)}`,
        ""
      )}`;
    }

    cityPath = `/${createSlug(city.country.name)}/${city.slug.replace(
      `-${createSlug(city.country.name)}`,
      ""
    )}`;

    if (city.regionCities && city.regionCities.length > 0) {
      cityPath = `/${createSlug(
        city.country.name
      )}/${city.regionCities[0].region.slug.replace(
        `-${createSlug(city.country.name)}`,
        ""
      )}/${city.slug.replace(`-${createSlug(city.country.name)}`, "")}`;
    }
  }

  useEffect(() => {
    // Use a delay or any other condition to control when the video should load.
    // For example, you can load it after a certain amount of time or when a user interacts with it.
    const timeout = setTimeout(() => {
      setShowVideo(true);
    }, 5000); // Load the video after 5 seconds (adjust this delay as needed)

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Page>
      <TopBar showSearch={true} />
      <Section>
        <div className="article">
          <div className="article-content">
            {city && (
              <Breadcrumbs>
                <a href={countryPath}>{city.country.name}</a> /
                {city.regionCities && city.regionCities.length > 0 && (
                  <>
                    <a href={regionPath}>{city.regionCities[0].region.name}</a>{" "}
                    /{" "}
                  </>
                )}
                <a href={cityPath}>
                  {city.name.replace(`, ${city.country.name}`, "")}
                </a>
              </Breadcrumbs>
            )}

            <p className="author-last-updated">Published: {formattedDate}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: formatHtml(
                  content.childMarkdownRemark.html,
                  followLinks?.childMarkdownRemark.rawMarkdownBody
                ),
              }}
            />
            {showVideo && youtubeLink && (
              <iframe
                width="560"
                height="315"
                src={youtubeLink}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                style={{ maxWidth: "100%" }}
              ></iframe>
            )}
          </div>
          <div className="article-sidebar">
            <div className="article-sidebar-section">
              <div className="article-sidebar-title">About Us</div>
              <div>
                We are a travel and price comparison platform that provides
                travelers with access to over half a million tours, activities,
                day trips, and transfers worldwide, all at the best possible
                prices.
              </div>
            </div>
            <div className="article-sidebar-section">
              <SubscribeForm compact />
            </div>
            <div className="article-sidebar-section">
              <div>
                <div className="social-media-icons">
                  <a
                    href="https://www.youtube.com/@travel-lingual"
                    target="_blank"
                  >
                    <img className="social-media-icon" src={YoutubeIcon} />
                  </a>
                  <a
                    href="https://linkedin.com/in/james-a-smith-bbb80582"
                    target="_blank"
                  >
                    <img className="social-media-icon" src={LinkedInIcon} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@travellingual"
                    target="_blank"
                  >
                    <img className="social-media-icon" src={TikTokIcon} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* {article.city && (
        <Section backgroundColor="#f5f5f5">
          <SectionHeading component="h2">
            SEE MORE THINGS TO DO IN {article.city.name.toUpperCase()}
          </SectionHeading>
          <SearchResultCarousel
            slides={
              cityActivities &&
              cityActivities.map((activity) => (
                <SearchResult compact={true} searchResult={activity} />
              ))
            }
          />
        </Section>
      )} */}
      {showBanner && (
        <ArticleBanner
          bannerText={bannerText}
          bannerUrl={bannerUrl}
          bannerCtaText={bannerCtaText}
        />
      )}
      <FeaturedIn />
      <Footer bannerShowing={showBanner} />
    </Page>
  );
};

export default NonCityArticleTemplate;

export const Head = ({ data }) => {
  const { title, description, thumbnailImageUrl, createdAt } =
    data.contentfulArticle;
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={title}
      description={
        description && description.childMarkdownRemark.rawMarkdownBody
      }
      image={thumbnailImageUrl}
      pathname={location.pathname}
      publishedDate={createdAt}
    />
  );
};
